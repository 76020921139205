import Swiper, { Navigation, Pagination, Grid, FreeMode } from "swiper";
Swiper.use([Navigation, Pagination, Grid, FreeMode]);

const tariffSliderList = document.querySelectorAll(".tariff-slider");

tariffSliderList.forEach((tariffSlider, i) => {
    const slidesCount = tariffSlider.querySelectorAll(".swiper-slide").length;

    new Swiper(tariffSlider, {
        pagination: {
            el: `.tariff-slider-pagination-${i + 1}`,
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>';
            },
        },
        navigation: {
            enabled: true,
            nextEl: `.tariff-slider-next-${i + 1}`,
            prevEl: `.tariff-slider-prev-${i + 1}`,
        },
        watchOverflow: true,
        slidesPerView: 1,
        breakpoints: {
            650: {
                slidesPerView: 2,
            },
            1050: {
                slidesPerView: slidesCount < 3 ? slidesCount : 3,
            },
            1430: {
                slidesPerView: slidesCount < 4 ? slidesCount : 4,
            },
            1850: {
                slidesPerView: slidesCount < 5 ? slidesCount : 5,
                watchOverflow: true,
            },
        },
    });
});

new Swiper(".skills-slider", {
    pagination: {
        el: ".skills__pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    },
    slidesPerView: 1,
    grid: {
        fill: "row",
        rows: 2,
    },
    spaceBetween: 30,
    breakpoints: {
        992: {
            slidesPerView: 2,
            grid: {
                fill: "row",
                rows: 2,
            },
            spaceBetween: 30,
        },
        1441: {
            slidesPerView: 2,
            grid: {
                fill: "row",
                rows: 2,
            },
            spaceBetween: 60,
        },
    },
});

new Swiper(".news-slider", {
    pagination: {
        el: ".news__pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    },
    navigation: {
        enabled: true,
        nextEl: `.news-slider-next`,
        prevEl: `.news-slider-prev`,
    },
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
        576: {
            spaceBetween: 30,
            slidesPerView: 1,
        },
        768: {
            spaceBetween: 30,
            slidesPerView: 2,
        },
        992: {
            spaceBetween: 30,
            slidesPerView: 2,
        },
        1300: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
});

new Swiper(".reviews-slider", {
    pagination: {
        el: ".reviews__pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    },
    navigation: {
        enabled: true,
        nextEl: `.reviews-slider-next`,
        prevEl: `.reviews-slider-prev`,
    },
    slidesPerView: 1,
    breakpoints: {
        786: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1400: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
});

new Swiper(".tags-slider", {
    navigation: {
        enabled: true,
        nextEl: ".tags__slider-next",
        prevEl: ".tags__slider-prev",
    },
    slidesPerView: "auto",
    freeMode: true,
    spaceBetween: 30,
});
